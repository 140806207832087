@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Manrope';
  src: url('../public/fonts/Manrope/Manrope-Regular.woff2') format('woff2'),
    url('../public/fonts/Manrope/Manrope-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope';
  src: url('../public/fonts/Manrope/Manrope-SemiBold.woff2') format('woff2'),
    url('../public/fonts/Manrope/Manrope-SemiBold.woff') format('woff');
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'Manrope';
  src: url('../public/fonts/Manrope/Manrope-Bold.woff2') format('woff2'),
    url('../public/fonts/Manrope/Manrope-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}

html {
  @apply font-body;
}

.react-datepicker-wrapper input[type="text"]{
    border: none;
}

.ql-editor {
  min-height: 150px;
  max-height: 150px;
}

.ql-toolbar.ql-snow, .ql-container.ql-snow{
  border: 0 !important;
}
.quill .ql-toolbar.ql-snow{
  border-bottom: 1px solid #ccc !important;
}